class ChurchdeskBrowser {
    constructor(container, options) {
        this.container = $(container);
        if (this.container.length === 0) {
            return;
        }

        this.events = null;
        this.eventsById = {};
        this.eventDates = [];
        this.eventsByDate = {};

        this.targetDate = null;
        this.targetPosition = null;

        this.filter = {
            eventTypes: [],
            locations: [],
            targetGroups: [],
            title: ""
        };

        this.dateObserver = null;
        this.visibleDates = new Set();

        this.opts = {
            districtsAsColumns: true,           // Each district gets a separate column
            autoMergeDistrictColumns: true,     // On smaller screens, columns are merged
            dateSize: "large",

            allowFilterLocations: true,
            allowFilterTargetGroups: true,
            allowFilterEventType: true,
            allowFilterTitle: true,

            showContributors: false,
            showEventBadges: true,
            showTargetGroupBadges: true,
            showDistrictBadges: true,
            showLocationBadges: true,
            smartLocationBadges: true,
            hideStaticFilterBadges: true,

            showLocation: true,
            showLocationAddress: true,
            showLocationBuildings: true,
            showLocationRooms: true,
            showPrimaryLocationOnly: false,            

            locationAddressAsPopover: true,
            allowLocationFallback: true,          
            smartRoomNaming: true,

            showTitle: true,
            showTime: true,
            showModals: false,

            startTimeName: "default",
            endTimeName: "default",

            sortBy: ["district", "start-time"],

            staticFilter: {},
        };

        this.previousWidth = this.container.width();
        this.popovers = [];

        this.widthObserver = new ResizeObserver(function(entries) {
            for (let entry of entries) {
                let width = entry.borderBoxSize[0].inlineSize;
                if (width !== this.previousWidth) {
                    this.onWidthChange(this.previousWidth, width);
                    this.previousWidth = width;
                }
            }
        }.bind(this));
        this.widthObserver.observe(this.container[0]);

        this.columnMinWidth = 350;           
        this.columnBuffer = 100;           
        this.showAsColumns = null;

        for (let key in this.opts) {
            if (key in options) {
                this.opts[key] = options[key];
            } else if (key.toLowerCase() in options) {
                this.opts[key] = options[key.toLocaleLowerCase()];
            }
        }

        this.filterObjects = {
            eventTypes: null,
            locations: null,
            targetGroups: null,
            title: null
        }

        this.initialized = this.init();
    }

    copy(object) {
        return JSON.parse(JSON.stringify(object));
    }

    loadHistoryState() {
        let state = window.history.state;
        if (state !== null) {
            if ("options" in state) {
                this.opts = this.copy(state.options);
            }
            if ("filter" in state) {
                this.filter = this.copy(state.filter);
            }
            if ("date" in state) {
                this.targetDate = state.date;
            }
            if ("scrollPosition" in state) {
                this.targetPosition = state.scrollPosition;
            }
        }
    }

    async loadUrlParameters(url) {        
        let date = url.searchParams.get("date");
        if (date !== null) {
            this.targetDate = date;
        }

        for (let key of ["eventTypes", "locations", "targetGroups"]) {
            let values = url.searchParams.get(key);
            if (values !== null) {
                for (let value of values.split(",")) {
                    await this.filterObjects[key].select(value, false);                    
                }
                this.filter[key] = [];
                for (let category of this.filterObjects[key].getSelection()) {
                    this.filter[key].push(category);
                }
            }
        }

        let title = url.searchParams.get("title");
        if (title !== null) {
            this.filter.title = title;
            this.filterObjects.title.val(title);
        }

        this.updateFilter();
        this.updateHistoryState();
    }

    updateHistoryState() {
        let dates = Array.from(this.visibleDates.values());
        let date = null;
        if (dates.length > 0) {
            date = dates.sort()[0];
        }
        let state = {
            scrollPosition: $(window).scrollTop(),
            date: date,
            filter: this.filter,
            options: this.opts
        };
        window.history.replaceState(this.copy(state), document.title, location.pathname);
    }

    async init() {
        this.container.html(`
            <div class="churchdesk-browser-interface">

            </div>
            <div class="churchdesk-browser-event-container">
                <div style="text-align: center;">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Wird geladen...</span>
                    </div>
                </div>
            </div>
        `);

        
        let url = new URL(location.href);
        this.bindBrowserEvents();
        this.loadHistoryState();
        await this.displayEvents();
        this.initFilters();
        await this.loadUrlParameters(url);
        if (this.targetPosition !== null) {
            $(window).scrollTop(this.targetPosition);
            $(".smart-scroll").addClass("smart-scroll-hidden");
        } else if (this.targetDate !== null) {
            let row = this.container.find(`.churchdesk-browser-row[data-date="${this.targetDate}"]`);
            if (row.length) {
                $(window).scrollTop(row.offset().top);
                $(".smart-scroll").addClass("smart-scroll-hidden");
            }
        }
    }

    async getDistricts() {
        let districts = await CategorySemantics.getSemantic("locations/districts");
        districts.sort((a, b) => {return a.name.localeCompare(b.name)});
        return districts;
    }

    async getDistrictCount() {
        let districts = await this.getDistricts();
        return districts.length;
    }

    async checkShowAsColumns() {
        if (this.opts.districtsAsColumns) {
            if (this.opts.autoMergeDistrictColumns) {
                let districtCount = await this.getDistrictCount();
                let requiredWidth = this.columnBuffer + districtCount * this.columnMinWidth;
                if (this.previousWidth < requiredWidth) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    async onWidthChange(oldWidth, newWidth) {
        await this.initialized;
        let showAsColumns = await this.checkShowAsColumns();
        if (this.showAsColumns !== showAsColumns) {
            this.showAsColumns = showAsColumns;
            this.displayEvents();
        }
    }

    initFilters() {        
        // Filter
        let filterInterface = $(`<div class="churchdesk-browser-filters"></div>`);
        this.container.find(".churchdesk-browser-interface").append(filterInterface);
        let filterSync = $(`<div class="churchdesk-browser-filter-sync"></div>`);
        this.container.find(".churchdesk-browser-interface").append(filterSync);
        
        filterInterface.html();
        if (this.opts.allowFilterLocations) {
            let locationFilterDiv = $(`<div class="churchdesk-browser-filter-locations"></div>`);
            filterInterface.append(locationFilterDiv);
            this.filterObjects.locations = new CategorySelector(locationFilterDiv, {
                title: "Orte",
                selected: this.filter.locations,
                icon: "fa-solid fa-location-dot",
                colorSelection: "semanticRoot",
                semantic: "locations/districts",
                syncWith: filterSync,
                onSelectionChange: this.filterLocations.bind(this)
            });
        }
        
        if (this.opts.allowFilterTargetGroups) {
            let targetGroupsFilterDiv = $(`<div class="churchdesk-browser-filter-targetgroups"></div>`);
            filterInterface.append(targetGroupsFilterDiv);
            this.filterObjects.targetGroups = new CategorySelector(targetGroupsFilterDiv, {
                title: "Zielgruppen",
                selected: this.filter.targetGroups,
                icon: "fa-solid fa-people-group",
                colorSelection: "hierarchy",
                semantic: "targetgroups",
                syncWith: filterSync,
                onSelectionChange: this.filterTargetGroups.bind(this)
            });
        }
        
        if (this.opts.allowFilterEventType) {
            let eventTypeFilterDiv = $(`<div class="churchdesk-browser-filter-event-types"></div>`);
            filterInterface.append(eventTypeFilterDiv);
            this.filterObjects.eventTypes = new CategorySelector(eventTypeFilterDiv, {
                title: "Veranstaltungstypen",
                selected: this.filter.eventTypes,
                icon: "fa-solid fa-tag",
                colorSelection: "semanticParent",
                semantic: "events",
                syncWith: filterSync,
                onSelectionChange: this.filterEventTypes.bind(this)
            });
        }

        if (this.opts.allowFilterTitle) {
            let titleFilterDiv = $(`<div class="churchdesk-browser-filter-title"></div>`);
            filterInterface.append(titleFilterDiv);
            let titleFilterInput = $(`<input type="text" placeholder="Suchen" class="churchdesk-search-input" />`);
            this.filterObjects.title = titleFilterInput;
            titleFilterDiv.append(titleFilterInput);
            titleFilterInput.val(this.filter.title);
            let that = this;
            titleFilterInput.on("keyup paste", function() {
                that.filterTitle($(this).val());
            });
        }

        if (this.opts.allowFilterLocations || this.opts.allowFilterTargetGroups || this.opts.allowFilterEventType || this.opts.allowFilterTitle) {
            let clearFilterButton = $(`
                <button class="btn churchdesk-browser-clear-filter">
                    <i class="fa-solid fa-xmark"></i>
                </button>`);
            filterInterface.append(clearFilterButton);
            clearFilterButton.on("click", function() {
                this.clearFilter();
            }.bind(this));
        }

        this.updateFilter();
    }

    filterTargetGroups(targetGroups) {
        this.filter.targetGroups = targetGroups;
        this.updateFilter();
    }

    filterEventTypes(eventTypes) {
        this.filter.eventTypes = eventTypes;
        this.updateFilter();

    }

    filterLocations(locations) {
        this.filter.locations = locations;
        this.updateFilter();
    }

    filterTitle(title) {
        if (title === null) {
            return;
        }
        this.filter.title = title;
        this.updateFilter();
    }
    
    matchesFilter(filter, event) {
        let matchesDistricts = true;
        let matchesEventTypes = true;
        let matchesTargetGroups = true;
        let matchesTitle = true;
        let matchesSummary = true;

        // Check districts (any) with hierarchy
        if (filter.locations.length > 0) {
            matchesDistricts = Categories.verifyFilter(filter.locations, event.locations, true, false, true);
        }

        // Check event types (any), no hierarchy
        if (filter.eventTypes.length > 0) {
            matchesEventTypes = Categories.verifyFilter(filter.eventTypes, event.eventTypes, true, false, false);
        }

        // Check target groups (all), with hierarchy
        if (filter.targetGroups.length > 0) {
            matchesTargetGroups = Categories.verifyFilter(filter.targetGroups, event.targetgroups, false, true, false);
        }

        // Check title search
        if (filter.title !== null && filter.title.length > 0) {
            matchesTitle = event.title.toLocaleLowerCase().includes(filter.title.toLocaleLowerCase());            
        }
        // Check summary search
        if (filter.title !== null && filter.title.length > 0) {
            matchesSummary = event.summary.toLocaleLowerCase().includes(filter.title.toLocaleLowerCase());            
        }

        return matchesDistricts && matchesEventTypes && matchesTargetGroups && (matchesTitle || matchesSummary);
    }

    anyFilterActive() {
        for (let key in this.filter) {
            if (this.filter[key] !== false) {
                if (Array.isArray(this.filter[key])) {
                    if (this.filter[key].length > 0) {
                        return true;
                    }
                } else {
                    return true;
                }
            }
        }
        return false;
    }

    clearFilter() {
        this.filter.eventTypes = [];
        this.filter.locations = [];
        this.filter.targetGroups = [];
        this.filter.title = "";

        this.filterObjects.eventTypes.clearSelection(false);
        this.filterObjects.locations.clearSelection(false);
        this.filterObjects.targetGroups.clearSelection(false);
        this.filterObjects.title.val("");

        this.updateFilter();
    }

    updateFilter() {
        let that = this;
        // this.updateHistoryState();
        // Filter individual events
        this.container.find(".churchdesk-browser-event").each(function() {
            let eventId = $(this).data("event-id")
            let event = that.eventsById[eventId];

            if (that.matchesFilter(that.filter, event)) {
                $(this).addClass("show").removeClass("hide");
            } else {
                $(this).addClass("hide").removeClass("show");
            }
        });
        // Hide non-matching rows
        this.container.find(".churchdesk-browser-row").each(function() {
            if ($(this).find(".churchdesk-browser-event.show").length === 0) {
                $(this).hide();
            } else {
                $(this).show();
            }
        });
    }

    sortEvents(events) {
        return events;
    }

    async getEventDistrict(event) {        
        let districts = event.districts ?? [];
        if (districts.length >= 1) {
            if (districts.length > 1) {
                console.warn("Multiple districts for event " + event.id);
            }
            return districts[0];
        }
        return null;
        /*
        for (let locationId in event.locations) {
            let district = await CategorySemantics.getAssociatedDistrict(event.locations[locationId]);
            if (district !== false) {
                return district;
            }
        }
        return null;*/
    }

    async displayEvents() {
        let events = await this.getEvents();
        for (let popover of this.popovers) {
            popover.dispose();
        }
        this.popovers = [];

        if (this.showAsColumns === null) {
            this.showAsColumns = await this.checkShowAsColumns();
        }

        let container = this.container.find(".churchdesk-browser-event-container");
        container.html(`<div class="event-list"></div>`);
        container = container.find("> div");

        if (this.showAsColumns) {
            container.append(await this.makeHeaderRow());
        }
        
        for (let date of this.eventDates) {
            container.append(await this.makeDateRow(date));
        }

        events = this.sortEvents(events);
        
        for (let event of events) {
            let eventContainer = await this.getEventContainer(event);
            if (eventContainer === null) {
                console.error("No container found for " + event.id);
                console.log(event);
                continue;
            }
            if (eventContainer.find(`.churchdesk-browser-event[data-event-id="${event.id}"]`).length > 0) {
                // Avoid duplicates
                continue;
            }
            eventContainer.append(await this.makeEventEntry(event));
        }
        let that = this;        
        container.find(".popover-trigger").each(function() {
            that.popovers.push(new bootstrap.Popover($(this)[0], {"trigger": "hover focus"}));
        })
        this.dateObserver.disconnect();
        this.visibleDates.clear();
        this.container.find(".churchdesk-browser-row").each(function() {
            that.dateObserver.observe($(this)[0]);
        });
    }

    async getEventContainer(event) {
        let row = this.container.find(`.churchdesk-browser-row[data-date=${this.getEventDateString(event)}]`)
        if (row.length === 0) {
            return null;
        }
        if (this.showAsColumns) {
            let district = await this.getEventDistrict(event);
            if (district !== null) {
                let container = row.find(`.churchdesk-browser-events-district-${district.id}`);
                if (row.length > 0) {
                    return $(container[0]);
                }
            }
        }
        let container = row.find(`.churchdesk-browser-events-default`);
        if (container.length > 0) {
            return $(container[0]);
        }
        return null;
    }

    async makeHeaderRow() {    
        let headerRow = $(`
            <div class="churchdesk-browser-header">
                <div class="churchdesk-browser-row-date"></div>
            </div>
        `);
        for (let district of await this.getDistricts()) {
            headerRow.append(`<div class="churchdesk-browser-row-district">${district.name}</div>`);
        }
        return headerRow;
    }

    async makeDateRow(date) {
        let wrappable = this.showAsColumns ? "" : "wrappable";
        let cardClass = this.showAsColumns ? "" : "event-row";

        let defaultRow = `<div class="churchdesk-browser-events churchdesk-browser-row-district churchdesk-browser-events-default"></div>`;
        if (this.showAsColumns) {
            defaultRow = "";
            for (let district of await this.getDistricts()) {
                defaultRow += `<div class="churchdesk-browser-events churchdesk-browser-row-district churchdesk-browser-events-district-${district.id}"></div>`;
            }
        }
        
        let dayClass = "";
        if (date.isoWeekday() === 6) {
            dayClass = "saturday";
        } else if (date.isoWeekday() === 7) {
            dayClass = "sunday";
        }

        let elapsedClass = date.isBefore(moment().startOf("day")) ? "elapsed" : "";
        let calendar = `
            <div class="calendar calendar-${this.opts.dateSize}">
                <div class="month ${dayClass}">${date.format("MMMM")}</div>
                <div class="day">${date.format("DD")}</div>
                <div class="dayname">${date.format("ddd")}</div>
                <div class="year">${date.format("YYYY")}</div>
            </div>
        `;

        let row = $(`
            <div class="churchdesk-browser-row ${wrappable} ${cardClass} ${elapsedClass}" data-date="${this.getDateString(date)}">                    
                <div class="churchdesk-browser-row-date">
                    ${calendar}
                </div>
                ${defaultRow}
            </div>
        `);        
        return row;
    }

    formatEventDateTime(event) {
        let eventTime = null;
        let startDate = this.getEventStartDate(event);
        let endDate = this.getEventEndDate(event);
        if (event.allDay) {            
            eventTime = `${endDate.format("L")}`;            
            if (!startDate.isSame(endDate, "day")) {
                eventTime = `${eventTime} bis ${endDate.format("L")}`;
            }
        } else {
            eventTime = `${startDate.format("L")}, ${startDate.format("LT")} Uhr`;

            if (event.showEndtime) {
                if (endDate.isSame(startDate, "day")) {
                    eventTime = `${eventTime} bis ${endDate.format("LT")} Uhr`;
                } else {
                    eventTime = `${eventTime} bis ${endDate.format("L")}, ${endDate.format("LT")} Uhr`;
                }
            }
        }
        return eventTime;
    }

    formatEventTime(event) {
        if (!this.opts.showTime) {
            return null;
        }

        let eventTime = null;

        if (event.allDay) {
            eventTime = "Ganztägig";
            let startDate = this.getEventStartDate(event);
            let endDate = this.getEventEndDate(event);
            if (!startDate.isSame(endDate, "day")) {
                eventTime = `Mehrtägig // Endet am ${endDate.format("L")}`;
            }
        } else {
            let startDate = this.getEventStartDate(event);
            let startTime = startDate.format("LT");
            eventTime = `${startTime} Uhr`;
            if (event.showEndtime) {
                let endDate = this.getEventEndDate(event);
                if (endDate.isSame(startDate, "day")) {
                    let endTime = endDate.format("LT");
                    eventTime = `${startTime} bis ${endTime} Uhr`;
                } else {
                    eventTime = `${startTime} Uhr // Endet am ${endDate.format("L")} um ${endDate.format("LT")} Uhr`;
                }                
            }
        }
        return eventTime;
    }

    exactFilterExists(filter, category) {
        for (let o of filter) {
            if (o.id === category.id) {
                return true;
            }
        }
        return false;
    }

    async formatEventBadges(event, forModal = false) {
        if (!this.opts.showEventBadges) {
            return null;
        }
        let badges = [];
        if (this.opts.showLocationBadges) {            
            if (!this.showAsColumns || !this.opts.smartLocationBadges || forModal) {
                for (let district of event.districts) {
                    if (this.exactFilterExists(this.opts.staticFilter.locations, district)) {
                        continue;
                    }
                    badges.push(await Categories.getBadge(district, {"type": "location"}));
                }
            }            
        }
        if (this.opts.showTargetGroupBadges) {
            for (let targetGroup of event.targetGroups) {
                if (this.exactFilterExists(this.opts.staticFilter.targetGroups, targetGroup)) {
                    continue;
                }
                badges.push(await Categories.getBadge(targetGroup, {"type": "targetgroup"}));
            }
        }
        if (this.opts.showEventBadges) {
            for (let eventType of event.eventTypes) {
                if (this.exactFilterExists(this.opts.staticFilter.eventTypes, eventType)) {
                    continue;
                }
                badges.push(await Categories.getBadge(eventType, {"type": "eventtype"}));
            }
        }
        if (badges.length > 0) {
            return badges.join("");
        }
        return null;
    }

    async formatEventLocation(event) {
        if (!this.opts.showLocation && !this.opts.showLocationAddress) {
            return null;
        }

        let eventBuildings = {};

        let primaryBuilding = null;
        let primaryBuildingName = null;
        let primaryBuildingAddress = null;
        
        let primaryRoom = null;
        let primaryRoomName = null;

        if (event.buildings.length > 0) {
            primaryBuilding = event.buildings[0];
            primaryBuildingName = primaryBuilding.name;
            primaryBuildingAddress = primaryBuilding.addressRows;
            for (let building of event.buildings) {
                eventBuildings[building.id] = building;
            }
        }

        if (event.rooms.length > 0) {
            primaryRoom = event.rooms[0];
            primaryRoomName = primaryRoom.name;
        }

        if (primaryBuilding === null) {
            // Try fallback
            if (this.opts.allowLocationFallback) {
                primaryBuildingName = event.locationName;                      
                try {
                    let addressParts = [];
                    let loc = event.locationObj;
                    addressParts.push(loc.address ?? null);
                    addressParts.push(loc.address2 ?? null);
                    addressParts.push(loc.zipcode ?? null);
                    addressParts.push(loc.city ?? null);
                    addressParts = addressParts.filter((v, i, a) => {v !== null});
                    if (addressParts.length > 0) {
                        primaryBuildingAddress = addressParts.join(", ");                        
                    }
                } catch (e) {
                    console.error(e);
                }
            }
        }

        if (primaryBuilding === null && primaryBuildingAddress === null) {
            return null;
        }

        let buildings = {};
        let locationIcon = `<i class="fa-solid fa-location-dot"></i>`;
        let standaloneRooms = [];
        let buildingIds = [];
        let asPopover = this.opts.locationAddressAsPopover;

        if (this.opts.showLocation) {
            if (primaryBuilding === null) {
                // Use fallback
                let displayAddress = this.opts.showLocationAddress ? primaryBuildingAddress : null;                
                return `
                    <div class="event-locations">
                        ${this.makeLocationDiv(primaryBuildingName, displayAddress, locationIcon, false)}
                    </div>
                `;
            }

            if (this.opts.showLocationRooms && event.rooms.length > 0) {
                for (let room of event.rooms) {
                    let building = await CategorySemantics.getAssociatedBuilding(room);
                    if (building) {
                        if (building.id in eventBuildings) {
                            building = Object.assign({}, eventBuildings[building.id]);
                        } else {
                            console.error("Building found for event room not associated with event: " + building.id);
                        }

                        if (!buildingIds.includes(building.id)) {
                            buildingIds.push(building.id);
                            buildings[building.id] = building;
                            building.rooms = [];
                        } else {
                            building = buildings[building.id];
                        }
                        building.rooms.push(room);
                    } else {
                        standaloneRooms.push(room);
                    }                                                            
                }
            }
            if ((this.opts.showLocationBuildings || this.opts.showLocationRooms) && event.buildings.length > 0) {
                for (let building of event.buildings) {
                    if (buildingIds.includes(building.id)) {
                        continue;
                    }
                    building = Object.assign({}, building);
                    building.rooms = [];
                    buildingIds.push(building.id);
                    buildings[building.id] = building;
                }
            }

            if (buildings.length === 0 && standaloneRooms.length === 0) {
                console.error("No locations found. This should not happen.")
                return null;
            }
            if (this.opts.showPrimaryLocationOnly) {
                if (buildingIds.length > 0) {
                    buildingIds = [buildingIds[0]];
                    standaloneRooms = [];
                } else {
                    standaloneRooms = [standaloneRooms[0]];
                }
            }

            let locationDivs = [];            
            for (let buildingId of buildingIds) {
                let building = buildings[buildingId];                
                let buildingName = building.name
                let locationName = buildingName;
                let buildingAddress = building.addressRows;
                let locationAddress = this.opts.showLocationAddress ? buildingAddress : null;
                if (this.opts.showLocationRooms && building.rooms.length > 0) {
                    if (this.opts.smartRoomNaming) {
                        // Group rooms by building
                        let roomNames = [];
                        for (let room of building.rooms) {
                            roomNames.push(room.name);
                        }
                        let collectionName = `${buildingName} (${roomNames.join(", ")})`;
                        locationDivs.push(this.makeLocationDiv(collectionName, locationAddress, locationIcon, asPopover));
                    } else {
                        // One badge per room
                        for (let room of building.rooms) {
                            let roomName = `${buildingName} (${room.name})`;
                            locationDivs.push(this.makeLocationDiv(roomName, locationAddress, locationIcon, asPopover));
                        }
                    }
                } else {
                    locationDivs.push(this.makeLocationDiv(locationName, locationAddress, locationIcon, asPopover));
                }
            }

            if (standaloneRooms.length > 0 && this.opts.showLocationRooms) {
                for (let room of standaloneRooms) {
                    locationDivs.push(this.makeLocationDiv(room.name, null, locationIcon, asPopover));
                }
            }

            if (locationDivs.length === 0) {
                return null;
            }

            return `
                <div class="event-locations">
                    ${locationDivs.join("")}
                </div>
            `;

        } else {
            if (this.opts.showLocationAddress && primaryBuildingAddress !== null) {
                // Show just primary address
                return `
                    <div class="event-locations">
                        ${this.makeLocationDiv(null, primaryBuildingAddress, null, false)}
                    </div>
                `;
            }
        }

        return null;
    }

    makeLocationDiv(name, address, icon, asPopover) {
        let popOverAddress = null;
        let htmlAddress = "";
        let popoverData = "";
        let popoverClass = "";
        asPopover = true;

        if (address && address.length > 0) {
            if (asPopover) {
                popOverAddress = address.join("<br />");
                let popoverDataParts = [
                    `data-bs-toggle="popover"`,
                    `data-bs-placement="bottom"`,
                    `data-bs-container="body"`,
                    `data-bs-content="${popOverAddress}"`,
                    `data-bs-html="true"`
                ];
                popoverData = popoverDataParts.join(" ");
                popoverClass = "popover-trigger";
                htmlAddress = "";
            } else {
                htmlAddress = `
                    <span class="event-location-address">
                        ${address.join(", ")}
                    </span>`;
            }
        }

        let prefix = "";
        if (icon !== null && icon !== false) {
            prefix = `<span class="event-info-prefix">${icon}</span>`; //<i class="${icon}"></i>`;
        }

        if (name !== null) {            
            name = `<span class="event-location-name">${name}</span>`;
        } else {
            name = "";
        }

        if (name === "" && htmlAddress === "") {
            return "";
        }

        return ` 
            <div class="event-location event-info-prefixed ${popoverClass}" ${popoverData}>
                ${prefix}
                ${name}
            </div>`;
    }

    async showEventModal(event) {
        let defaultContent = ""
        let image = "";
        let description = "";

        if (event.summary !== null && event.summary !== "") {
            defaultContent += `<span class="event-description">${event.summary}</span>`;
        }

        let dateTime = this.formatEventDateTime(event);
        if (dateTime !== null) {
            defaultContent += `
                <span class="event-time">
                    <span class="event-info-prefix">
                        <i class="fa-regular fa-clock"></i>
                    </span>
                    ${dateTime}
                </span>`;
        }

        let eventLocationFormatted = await this.formatEventLocation(event);
        if (eventLocationFormatted !== null) {
            defaultContent += eventLocationFormatted;
        }
        
        let eventBadges = await this.formatEventBadges(event, true);
        if (eventBadges !== null) {
            defaultContent += `<div class="event-badges below">${eventBadges}</div>`;
        }

        if (event.image !== null) {
            let url = null;
            let blockKeys = ["copyright", "title"];
            for (let key in event.image) {
                if (blockKeys.includes(key)) {
                    continue;
                }
                url = event.image[key];
                break;
            }
            if (url !== null && typeof(url) !== "undefined") {
                image = `
                <div class="event-image">
                    <img src="${url}" class="churchdesk-event-image" />
                    <span class="copyright">${event.image.copyright}</span>
                </div>`;
            }            
        }

        if (event.description !== "") {
            description = `<div class="event-html">${event.description}</div>`;
        }

        let modalContainer = $(`
            <div class="modal fade churchdesk-event-modal churchdesk-event" data-event-id="${event.id}" tabindex="-1">
                <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">${event.title}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            ${defaultContent}
                            <div class="event-details">
                                ${description}
                                ${image}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        `);
        $("body").append(modalContainer);
        modalContainer.find(".event-details img").addClass("pi_image");
        
        modalContainer.find(".popover-trigger").each(function() {
            new bootstrap.Popover($(this)[0], {"trigger": "hover focus"});
        });

        let modal = new bootstrap.Modal(modalContainer[0], {});
        modalContainer.on("hidden.bs.modal", function() {
            modalContainer.remove();
        })
        modal.show();
    }

    async makeEventEntry(event) {
        let eventTimeDiv = "";
        let eventContributorsDiv = "";
        let eventDescriptionDiv = "";
        let eventLocationDiv = "";
        let eventBadgesDiv = "";

        let hasModal = event.description !== "" || event.image !== null;
        let showTitle = this.opts.showTitle;

        let eventContributors = event.contributor;
        let eventTime = this.formatEventTime(event);
        let eventLocationFormatted = await this.formatEventLocation(event);
        let eventBadges = await this.formatEventBadges(event);

        if (event.summary && event.summary.length > 0) {
            let modalTrigger = "";
            if (hasModal && !showTitle) {
                modalTrigger = "event-modal-trigger"
            }   
            eventDescriptionDiv = `<span class="event-description ${modalTrigger}">${event.summary}</span>`;
        }

        if (this.opts.showTime && eventTime !== null) {
            eventTimeDiv = `
                <span class="event-time">
                    <span class="event-info-prefix">
                        <i class="fa-regular fa-clock"></i>
                    </span>
                    ${eventTime}
                </span>`;
        }

        console.log(this.opts);
        console.log(this.opts.showContributors);
        console.log(this.opts.showContributors ? true : false);
        console.log(eventContributors);
        if (this.opts.showContributors && eventContributors !== null) {
            eventContributorsDiv = `
                <span class="event-contributors">
                    <span class="event-info-prefix">
                        <i class="fa-solid fa-people-group"></i>
                    </span>
                    ${eventContributors}
                </span>
            `;
        }

        if (eventLocationFormatted !== null) {
            eventLocationDiv = eventLocationFormatted;
        }
        if (eventBadges !== null) {
            eventBadgesDiv = `<div class="event-badges below">${eventBadges}</div>`;
        }

        let endDate = this.getEventEndDate(event);

        let highlightClass = event.isHighlight ? 'highlight' : '';        
        let elapsedClass = "";
        if (endDate.isBefore(moment())) {
            elapsedClass = "elapsed";
            highlightClass = "";
        }
        
        return `
            <div class="churchdesk-browser-event churchdesk-event ${highlightClass} ${elapsedClass}" data-event-id="${event.id}">
                <div class="event-background"></div>
                <span class="event-title ${showTitle ? "" : "hidden"} ${hasModal ? 'event-modal-trigger' : ''}">${event.title} ${hasModal ? '<i class="fa-solid fa-circle-info"></i>' : ''}</span>
                ${eventDescriptionDiv}
                ${eventTimeDiv}
                ${eventLocationDiv}             
                ${eventContributorsDiv}
                ${eventBadgesDiv}                
            </div>
        `;
    }

    getEventStartDate(event) {
        return moment(event.startTimestamp * 1000);
    }
    
    getEventEndDate(event) {
        return moment(event.endTimestamp * 1000);
    }

    getDateString(date) {
        return date.format("YYYY-MM-DD");
    }

    getEventDateString(event) {
        return `${this.getDateString(this.getEventStartDate(event))}`;
    }

    getEventsByDate(date) {
        let dateString = this.getDateString(date);
        if (dateString in this.eventsByDate) {
            return this.eventsByDate[dateString];
        }
        return [];
    }

    async getEvents() {        
        if (this.events !== null) {
            return this.events;
        }
        this.eventDates = [];
        this.eventsByDate = {};        

        let filter = this.opts.staticFilter;
        filter.locations = filter.locations ?? [];
        filter.eventTypes = filter.eventTypes ?? [];
        filter.targetGroups = filter.targetGroups ?? [];
        filter.title = filter.title ?? "";
        let apiFilter = {};
        if (filter.title !== "") {
            apiFilter.title = filter.title;
        }
        if (this.opts.startTimeName !== "default") {
            apiFilter.startTimeName = this.opts.startTimeName;
        }
        if (this.opts.endTimeName !== "default") {
            apiFilter.endTimeName = this.opts.endTimeName;
        }

        try {
            this.groupsById = {};
            let result = await $.ajax({
                "url": "api/churchdesk/get_events",
                "data": {
                    "filter": apiFilter
                }
            });
            this.events = [];
            let categories = result.categories;
            for (let event of result.events) {               
                event = this.expandEvent(event, categories);
                if (!this.matchesFilter(filter, event)) {
                    continue;
                }
                this.events.push(event);
                this.eventsById[event.id] = event;
                
                let date = moment(event.startTimestamp * 1000).startOf("day");
                let dateString = this.getDateString(date);
                if (!(dateString in this.eventsByDate)) {
                    this.eventDates.push(date);                    
                    this.eventsByDate[dateString] = [];
                }
                this.eventsByDate[dateString].push(event);
            }
            return this.events;
        } catch (e) {
            console.error(e);
            this.events = [];
            this.container.html("<i>Events konnten nicht abgerufen werden</i>");
            return [];
        }
    }

    expandEvent(event, categories) {
        let keys = ["districts", "buildings", "rooms", "locations", "targetGroups", "eventTypes", "activities"];
        for (let key of keys) {
            if (key in event) {
                let idArray = event[key];
                let expandedObject = [];
                for (let id of idArray) {
                    if (id in categories) {
                        expandedObject.push(categories[id]);
                    } else {
                        console.error(`Could not find ID for extension: ${id}`);
                        console.log(categories);
                    }
                }
                event[key] = expandedObject;
            }
        }
        return event;
    }

    bindBrowserEvents() {
        let that = this;
        this.container.off("click", ".event-modal-trigger");
        this.container.on("click", ".event-modal-trigger", function() {
            if (!that.opts.showModals) {
                return;
            }
            let eventId = $(this).parent().data("eventId");
            that.showEventModal(that.eventsById[eventId]);
        });

        $(window).on("beforeunload", function() {
            this.updateHistoryState();
        }.bind(this));

        this.dateObserver = new IntersectionObserver(function(entries) {
            for (let entry of entries) {
                let date = $(entry.target).data("date");
                if (entry.intersectionRatio > 0) {
                    that.visibleDates.add(date);
                } else if (that.visibleDates.size > 1) {
                    that.visibleDates.delete(date);
                }                
            }
            // that.updateHistoryState();
        }.bind(this));
    }
}

$(function() {
    $(".churchdesk-browser").each(async function() {
        let options = $(this).data();
        options.staticFilter = {
            eventTypes: [],
            locations: [],
            targetGroups: [],
            title: options.statictitle ?? ""
        };
        let eventTypes = options.staticeventtypes ?? [];
        let locations = options.staticlocations ?? [];
        let targetGroups = options.statictargetgroups ?? [];

        // Replace IDs with objects
        if (targetGroups.length > 0) {
            let all = await CategorySemantics.getSemanticObject("targetgroups");
            for (let id of targetGroups) {
                if (id in all) {
                    options.staticFilter.targetGroups.push(all[id]);
                }
            }
        }
        if (eventTypes.length > 0) {
            let all = await CategorySemantics.getSemanticObject("events");
            for (let id of eventTypes) {
                if (id in all) {
                    options.staticFilter.eventTypes.push(all[id]);
                }
            }
        }
        if (locations.length > 0) {
            let all = await CategorySemantics.getSemanticObject("locations/districts");
            for (let id of locations) {
                if (id in all) {
                    options.staticFilter.locations.push(all[id]);
                }
            }
        }

        console.log(options);

        new ChurchdeskBrowser($(this), options);
    });
});