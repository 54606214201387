class ChurchdeskAdmin {
    constructor() {
        this.categoryContainer = $("#churchdesk-event-categories-admin");
        this.resourceContainer = $("#churchdesk-event-resources-admin");
        this.init();
    }

    async init() {
        let that = this;
        $("#churchdesk_synchronize").on("click", async function() {
            $(this).prop("disabled", true);
            await that.synchronizeEvents();
            $(this).prop("disabled", false);
        });
        this.initCategoryManager();
        this.initResourceManager();
    }

    async synchronizeEvents() {
        try {
            let result = await $.ajax({
                url: "api/churchdesk/synchronize_events"
            });
            console.log(result);
            if (result["failed"] === 0) {
                new Notification().success(`${result.synchronized} Events synchronisiert.`);
            } else {
                new Notification().warning(`${result.synchronized} Events synchronisiert, ${result.failed} fehlgeschlagen`);
            }
        } catch (e) {
            console.log(e);
            new Notification().error(`Synchronisierung fehlgeschlagen`);
        }
    }

    /* 
     *   CATEGORIES 
     */
    initCategoryManager() {
        this.categoryContainer.html(`
            <button class="btn green darken-2 white-text waves-effect" id="save_categories_button">
                <i class="material-icons left">save</i>
                Speichern
            </button>
            <button class="btn amber darken-4 white-text waves-effect" id="reload_categories_button">
                <i class="material-icons left">sync</i>
                Neu laden
            </button>
            <button class="btn red darken-2 white-text waves-effect" id="delete_categories_button">
                <i class="material-icons left">delete</i>
                Löschen
            </button>
            <div class="churchdesk_category_admin_container"></div>
        `);
        this.categoryContainer.find("#save_categories_button").on("click", this.saveCategories.bind(this));
        this.categoryContainer.find("#reload_categories_button").on("click", function() {this.reloadCategories(true)}.bind(this));
        this.categoryContainer.find("#delete_categories_button").on("click", function() {this.deleteCategories(true)}.bind(this));
        this.reloadCategories();
    }

    async reloadCategories(forceUpdate = false) {
        let container = this.categoryContainer.find(".churchdesk_category_admin_container");
        container.html(`<div align="center">Daten werden geladen...</div>`);
        try {
            let response = await $.ajax({
                url: "api/churchdesk/get_extended_categories",
                data: {
                    "refresh": forceUpdate
                }
            });
            let categories = response.categories;
            container.html("");
            for (let category of categories) {
                container.append(this.makeCategoryManageBox(category));
            }
            container.find(".churchdesk_admin_category_select").each(function() {
                initAdminPObjectSelect($(this));
            });
            container.find(".churchdesk_admin_color_select").each(function() {
                initAdminColorInput($(this));
            });
            new Notification().success("Kategorien aktualisiert");
        } catch (e) {
            console.error(e);
            new Notification().error("Kategorien konnten nicht geladen werden");
            container.html(`<div align="center" class="red-text">Daten konnten nicht geladen werden</div>`);
            return;
        }
    }

    async saveCategories() {
        let categories = [];
        this.categoryContainer.find(".churchdesk_admin_category_box").each(function() {
            let id = $(this).data("category-id");
            let customName = $(this).find(".churchdesk_admin_category_name").val();
            let icon = $(this).find(".churchdesk_admin_category_icon").val();
            let color = $(this).find(".churchdesk_admin_color_select").val();
            let events = $(this).find(`#churchdesk_admin_category_events_${id}`).val();
            let targetgroups = $(this).find(`#churchdesk_admin_category_targetgroups_${id}`).val();
            if (!Array.isArray(events)) {
                events = [];
            }
            if (!Array.isArray(targetgroups)) {
                targetgroups = [];
            }
            let category = {
                "id": id,
                "customName": customName,
                "icon": icon,
                "customColor": color,
                "events": events,
                "targetgroups": targetgroups
            };
            categories.push(category);
        });

        try {
            await $.ajax({
                url: "api/churchdesk/update_extended_categories",
                type: "post",
                data: {
                    "categories": categories
                }
            });
            new Notification().success("Kategorien gespeichert");
        } catch (e) {
            console.error(e);
            new Notification().error("Daten konnten nicht gespeichert werden");
        }
    }

    async deleteCategories() {
        if (!window.confirm("Wirklick alle Daten löschen?")) {
            return;
        }
        try {
            await $.ajax({
                url: "api/churchdesk/clear_extended_categories",
                type: "post"                
            });
            new Notification().success("Kategorien gelöscht");
        } catch (e) {
            console.error(e);
            new Notification().error("Daten konnten nicht gelöscht werden");
        }
        this.reloadCategories(true);
    }

    makeCategoryManageBox(category) {
        return `
            <div class="card">
                <div class="churchdesk_admin_category_box card-content" data-category-id="${category["id"]}">
                    ${this.makeOptionBoxStatic("Churchdesk ID", category["id"])}
                    ${this.makeOptionBoxStatic("Churchdesk Name", category["name"])}
                    ${this.makeOptionBox("Anzeigename", `<input type="text" class="churchdesk_admin_category_name" value="${category["customName"]}"`)}
                    ${this.makeOptionBox(
                        "Event-Typen", 
                        `
                        <div class="churchdesk_admin_category_select"
                              data-input="churchdesk_admin_category_events_${category["id"]}" 
                              data-value='${JSON.stringify(category["events"])}'
                              data-title=""
                              data-selectcount="2"
                              data-name="Events"
                              data-objectlabler="default:indentedcategoryname"
                              data-objectfilter="default:semantic-events"
                              data-objectclass="\\P\\Categories\\Category"
                              data-objectname="Event-Typen">
                        </div>`
                    )}
                    ${this.makeOptionBox(
                        "Zielgruppen", 
                        `
                        <div class="churchdesk_admin_category_select"
                              data-input="churchdesk_admin_category_targetgroups_${category["id"]}" 
                              data-value='${JSON.stringify(category["targetgroups"])}'
                              data-title=""
                              data-selectcount="2"
                              data-objectlabler="default:indentedcategoryname"
                              data-name="Zielgruppen"
                              data-objectfilter="default:semantic-targetgroups"
                              data-objectclass="\\P\\Categories\\Category"
                              data-objectname="Zielgruppen">
                        </div>`
                    )}
                    ${this.makeOptionBox("Icon", `<input type="text" placeholder="fa:church" class="churchdesk_admin_category_icon" value="${category["icon"]}"`)}
                    ${this.makeOptionBox("Farbe", `<input type="text" class="churchdesk_admin_color_select" value="${category["customColor"]}"`)}
                </div>
            </div>
        `;
    }

    /* 
     *   RESOURCES 
     */
    initResourceManager() {
        this.resourceContainer.html(`
            <button class="btn green darken-2 white-text waves-effect" id="save_resources_button">
                <i class="material-icons left">save</i>
                Speichern
            </button>
            <button class="btn amber darken-4 white-text waves-effect" id="reload_resources_button">
                <i class="material-icons left">sync</i>
                Neu laden
            </button>
            <button class="btn red darken-2 white-text waves-effect" id="delete_resources_button">
                <i class="material-icons left">delete</i>
                Löschen
            </button>
            <div class="churchdesk_resource_admin_container"></div>
        `);
        this.resourceContainer.find("#save_resources_button").on("click", this.saveResources.bind(this));
        this.resourceContainer.find("#reload_resources_button").on("click", function() {this.reloadResources(true)}.bind(this));
        this.resourceContainer.find("#delete_resources_button").on("click", function() {this.deleteResources(true)}.bind(this));
        this.reloadResources();        
    }

    async reloadResources(forceUpdate = false) {
        let container = this.resourceContainer.find(".churchdesk_resource_admin_container");
        container.html(`<div align="center">Daten werden geladen...</div>`);
        try {
            let response = await $.ajax({
                url: "api/churchdesk/get_extended_resources",
                data: {
                    "refresh": forceUpdate
                }
            });
            let resources = response.resources;
            container.html("");
            for (let resource of resources) {
                container.append(this.makeResourceManageBox(resource));
            }
            container.find(".churchdesk_admin_category_select").each(function() {
                initAdminPObjectSelect($(this));
            });
            container.find(".churchdesk_admin_color_select").each(function() {
                initAdminColorInput($(this));
            });
            new Notification().success("Ressourcen aktualisiert");
        } catch (e) {
            console.error(e);
            new Notification().error("Ressourcen konnten nicht geladen werden");
            container.html(`<div align="center" class="red-text">Daten konnten nicht geladen werden</div>`);
            return;
        }
    }

    async saveResources() {
        let resources = [];
        this.resourceContainer.find(".churchdesk_admin_resource_box").each(function() {
            let id = $(this).data("resource-id");
            let customName = $(this).find(".churchdesk_admin_resource_name").val();
            let districts = $(this).find("#churchdesk_admin_resource_districts_" + id).val();
            if (districts === null) {
                districts = [];
            }
            let buildings = $(this).find("#churchdesk_admin_resource_buildings_" + id).val();
            if (buildings === null) {
                buildings = [];
            }
            let rooms = $(this).find("#churchdesk_admin_resource_rooms_" + id).val();
            if (rooms === null) {
                rooms = [];
            }
            let comments = $(this).find(".churchdesk_admin_resource_comments").val();
            let icon = $(this).find(".churchdesk_admin_resource_icon").val();
            let color = $(this).find(".churchdesk_admin_color_select").val();
            let resource = {
                "id": id,
                "customName": customName,
                "districts": districts,
                "buildings": buildings,
                "rooms": rooms,
                "comments": comments,
                "icon": icon,
                "customColor": color
            };
            resources.push(resource);
        });

        try {
            await $.ajax({
                url: "api/churchdesk/update_extended_resources",
                type: "post",
                data: {
                    "resources": resources
                }
            });
            new Notification().success("Resourcen gespeichert");
        } catch (e) {
            console.error(e);
            new Notification().error("Daten konnten nicht gespeichert werden");
        }
    }

    async deleteResources() {
        if (!window.confirm("Wirklick alle Daten löschen?")) {
            return;
        }
        try {
            await $.ajax({
                url: "api/churchdesk/clear_extended_resources",
                type: "post"                
            });
            new Notification().success("Resourcen gelöscht");
        } catch (e) {
            console.error(e);
            new Notification().error("Daten konnten nicht gelöscht werden");
        }
        this.reloadResources(true);
    }

    makeResourceManageBox(resource) {
        return `
            <div class="card">
                <div class="churchdesk_admin_resource_box card-content" data-resource-id="${resource["id"]}">
                    ${this.makeOptionBoxStatic("Churchdesk ID", resource["id"])}
                    ${this.makeOptionBoxStatic("Churchdesk Name", resource["name"])}
                    ${this.makeOptionBox("Anzeigename", `<input type="text" class="churchdesk_admin_resource_name" value="${resource["customName"]}"`)}
                    ${this.makeOptionBox("Stadtteil", `
                        <div class="churchdesk_admin_category_select"
                            data-input="churchdesk_admin_resource_districts_${resource["id"]}" 
                            data-value='${JSON.stringify(resource["districts"])}'
                            data-title=""
                            data-selectcount="2"
                            data-name="Kategorie"
                            data-objectfilter="default:semantic-locations/districts"
                            data-objectlabler="default:locations:district"
                            data-objectclass="\\P\\Categories\\Category"
                            data-objectname="Stadtteile">
                        </div>`
                    )}
                    ${this.makeOptionBox("Gebäude", `
                        <div class="churchdesk_admin_category_select"
                            data-input="churchdesk_admin_resource_buildings_${resource["id"]}" 
                            data-value='${JSON.stringify(resource["buildings"])}'
                            data-title=""
                            data-selectcount="2"
                            data-name="Gebäude"
                            data-objectfilter="default:semantic-locations/buildings"
                            data-objectlabler="default:locations:building"
                            data-objectclass="\\P\\Categories\\Category"
                            data-objectname="Gebäude">
                        </div>`
                    )}
                    ${this.makeOptionBox("Räume", `
                        <div class="churchdesk_admin_category_select"
                            data-input="churchdesk_admin_resource_rooms_${resource["id"]}" 
                            data-value='${JSON.stringify(resource["rooms"])}'
                            data-title=""
                            data-name="Räume"
                            data-selectcount="2"
                            data-objectfilter="default:semantic-locations/rooms"
                            data-objectlabler="default:locations:room"
                            data-objectclass="\\P\\Categories\\Category"
                            data-objectname="Räume">
                        </div>`
                    )}
                    ${this.makeOptionBox("Kommentare", `<input type="text" class="churchdesk_admin_resource_comments" value="${resource["comments"]}"`)}
                    ${this.makeOptionBox("Icon", `<input type="text" placeholder="fa:church" class="churchdesk_admin_resource_icon" value="${resource["icon"]}"`)}
                    ${this.makeOptionBox("Farbe", `<input type="text" class="churchdesk_admin_color_select" value="${resource["customColor"]}"`)}                   
                </div>
            </div>
        `;
    }

    /*
     * 
     */
    makeOptionBoxStatic(key, option) {
        return this.makeOptionBox(key, `<div>${option}</div>`)
    }

    makeOptionBox(key, option) {
        return `
            <div class="churchdesk_admin_option">
                <label class="churchdesk_key">${key}</label>
                <span class="churchdesk_option">${option}</span>
            </div>`;
    }
}